import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/LanLogo-Header1.jpg';

const Nav = () => {
    const [showOptions, setShowOptions] = useState(false);
    const location = useLocation();

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    return (
        <nav className="bg-white border-gray-200 dark:bg-gray-900 sticky top-0 z-50">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-5" alt="Lanwoods Logo" />
                </a>
                <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    onClick={toggleOptions}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
                <div
                    className={`${
                        showOptions ? 'block' : 'hidden'
                    } w-full md:block md:w-auto `}
                    id="navbar-default"
                >
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li>
                            <Link
                                to="/"
                                onClick={() => {
                                    window.scrollTo(0, 0); // Scroll to the top of the screen
                                    setShowOptions(false); // Close the navigation menu
                                }}
                                className={`block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${location.pathname === '/' ? 'text-blue-700' : 'text-gray-900'}`}
                                aria-current={location.pathname === '/' ? 'page' : undefined}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/partners"
                                onClick={() => {
                                    window.scrollTo(0, 0); // Scroll to the top of the screen
                                    setShowOptions(false); // Close the navigation menu
                                }}
                                className={`block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${location.pathname === '/partners' ? 'text-blue-700' : 'text-gray-900'}`}
                            >
                                Our Partners
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                onClick={() => {
                                    window.scrollTo(0, 0); // Scroll to the top of the screen
                                    setShowOptions(false); // Close the navigation menu
                                }}
                                className={`block py-2 px-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ${location.pathname === '/contact' ? 'text-blue-700' : 'text-gray-900'}`}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
