import Textbox from "../components/textbox";
import Gallery from "../components/gallery";
const Home = () => {
    return (
        <div >
            <div className="bg-[url('./images/hero.png')] h-screen -mt-[10vh] bg-cover object-none object-bottom bg-fixed bg-center bg-no-repeat text-white py-20
            ">
                <div className="flex flex-col items-start justify-center h-3/4 p-10 ">
                    <h1 className="text-4xl font-bold text-white py-5">Experience Precision Scheduling <br />with Lanwoods.</h1>
                    <a href="/contact">
                    <button  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Contact Us
                    </button>
                    </a>

                </div>
            </div>


            <Textbox title="Who We Are" subtitle=" Industry Leaders ">
                Lanwoods is an independent UK-based consulting and managed support service business. <br /><br />

                Our consultants have vast experience transforming scheduling solutions throughout the food, beverage and biopharmaceutical industries. <br /><br />

                Lanwoods has a history of supporting intricate planning and scheduling solutions with clients throughout multiple countries. <br /><br />

                We have successfully designed and implemented both on-premises and cloud-based scheduling solutions integrated into Infor Cloud-Suite and SAP-based ERP systems across numerous industries throughout the UK, Europe and Asia. <br />

            </Textbox>
            <Gallery />
            <Textbox title="Our Partners" subtitle="Elevating Supply Chain Innovations">
                At Lanwoods, our alliance with Infor sits at the core of our mission to deliver cutting-edge solutions. <br /><br />

                As proud partners utilising Infor's Production Scheduling software, we offer both on-premises and cloud-based solutions that are at the pinnacle of supply chain innovation. <br /><br />

                This collaboration allows us to imbue our clients' operations with unrivalled value, expertise, and the breadth of capabilities necessary to meet complex planning demands with precision and scalability
            </Textbox>


        </div>
    );
};

export default Home;
