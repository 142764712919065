import sat_around_table from '../images/sat_around_table.jpg';
import sat_around_table2 from '../images/sat_around_table2.jpg';
import person_holding_mouse from '../images/person_holding_mouse.jpg';

const gallery = () => {
    return (
        <div className="md:pl-10 pt-10 pb-10 p-10 bg-slate-200">
            <div>
                <h2 className="text-langreen font-bold font-Raleway text-4xl">Our Services</h2>
                <h2 className="text-4xl pt-2">What We Do</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-5 md:px-20">
                <div className="flex flex-col items-center justify-center bg-slate-300 p-5 md:order-2">
                    <h3 className="md:text-lg text-sm  text-langreen font-bold">Precision Scheduling Solutions, Powered by Infor</h3>
                    <p className="mt-2 ">At Lanwoods, we specialise in custom integration
                        and enhancement of supply chain scheduling solutions, leveraging the power of Infor's software.
                        <br /><br />
                        Our expertise ensures your SQL database and system integrations are seamless,
                        elevating your existing operations to new heights of efficiency and performance.</p>
                </div>
                <div className="flex items-center justify-center md:order-1">
                    <img src={person_holding_mouse} alt="People sat around a table" className="w-full h-auto" />
                </div>

                <div className="flex flex-col items-center justify-center bg-slate-300 p-5 md:order-3">
                    <h3 className="md:text-lg text-sm   text-langreen font-bold">Certified Expertise in Project Management</h3>
                    <p className="mt-2 ">Lanwoods boasts a distinguished team of SIGMA & TOGAF certified professionals,
                        bringing a wealth of supply chain and execution experience across various industries.
                        <br /><br />
                        As certified Infor Trainers, we don't just manage projects—we empower your teams with knowledge and skills
                        for sustained excellence.</p>
                </div>
                <div className="flex items-center justify-center md:order-4">
                    <img src={sat_around_table2} alt="People sat around a table" className="w-full h-auto" />
                </div>

                <div className="flex flex-col items-center justify-center bg-slate-300 p-5  md:order-6">
                    <h3 className="md:text-lg text-sm  text-langreen font-bold">Comprehensive Training and Enduring Support</h3>
                    <p className="mt-2 ">At Lanwoods, we go beyond the basics to offer extensive product
                        and solution training, ensuring your team is equipped for success.
                        <br /><br />
                        Our managed services and enduring support are designed for longevity,
                        complemented by essential maintenance and application upgrades to keep your solutions at the forefront of efficiency.
                    </p>
                </div>
                <div className="flex items-center justify-center  md:order-5">
                    <img src={sat_around_table} alt="People sat around a table" className="w-full h-auto" />
                </div>
            </div>
        </div>
    );
}

export default gallery;
