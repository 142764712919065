
const Contact = () => {
    return (
        <div>
        {/* <!-- Hero Section --> */}
        <div className={`bg-[url('./images/people_walking.png')] h-screen -mt-[10vh] bg-cover object-none object-bottom bg-fixed bg-center bg-no-repeat text-white py-20`} >
             <div className="flex flex-col items-start justify-center h-3/4 p-10 ">
                <h1 className="text-4xl font-bold text-white py-5">Contact Us</h1>
                <p className="text-2xl">Ready to start your journey with Lanwoods?</p>
            </div>
        </div>

            <div className='flex flex-col md:flex-row justify-between px-10 pt-10 pb-10'>
                <div className='w-full md:w-1/4 mb-10 md:mb-0'>
                    <p className='text-lg'>
                        <span className='font-bold'>
                            Registered office only <br />
                            (not open for visitation)
                        </span>
                        <br />
                        8 The Westbury Centre Suite 3, <br />
                        Westbury Road,<br />
                        Newcastle,<br />
                        Staffordshire,<br />
                        United Kingdom,<br />
                        ST5 4LY</p>
                </div>
                <div className='w-full md:w-1/4 justify-end bg-slate-100'>
                    <p className='p-5 font-semibold'>Whether you're looking to discuss
                        a potential partnership,
                        kick off a new implementation,
                        or learn more,
                        we're here to help.
                        <br /><br />

                        Drop us an email at: <br />

                        <a className='text-blue-500 text-sm md:text-base' href = "mailto:enquiries@lanwoods.com">
                        enquiries@lanwoods.com
                        </a>
                        
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Contact;
