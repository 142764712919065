import React from "react";

import hands_in_circle from '../images/hands_in_circle.png';
import infor_logo from '../images/infor_logo.png';

const Partners = () => {
    return (
        <div>
            {/* <!-- Hero Section --> */}
            <div className={`bg-[url('./images/shaking-hands.png')] h-screen -mt-[10vh] bg-cover object-none object-bottom bg-fixed bg-center bg-no-repeat text-white py-20`} >
                <div className="flex flex-col items-start justify-center h-3/4 p-10 ">
                    <h1 className="text-4xl font-bold text-white py-5">Partners</h1>
                    <p className="text-2xl">Developing sustainable solutions, <br />
                        together.</p>
                </div>
            </div>


            <div className='grid md:grid-rows-1 grid-rows-2 grid-flow-col md:justify-between p-10'>
                <div className='md:w-1/2'>
                    <h1 className="text-langreen font-bold text-4xl">Our Strategy</h1>
                    <p className='text-lg'>Lanwoods continues to build strategic partnerships with
                        leading supply chain software companies to enable better solutions for our clients.
                        <br /><br />
                        Our newfound partnerships enabled us to deliver additional value,
                        expertise and quantity throughout our client's unique and challenging supply chain planning requirements</p>
                </div>
                <div className='lg:-mt-[30vh]  justify-end'>
                    <img className='lg:h-[70vh] w-auto md:pr-10  bg-cover object-none object-center bg-center bg-no-repeat' src={hands_in_circle} alt="People stood in a circle." />
                </div>
            </div>

            <div className="p-10 bg-slate-200">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 pt-5 md:px-20">
                    <div className="flex items-center justify-center">
                        <img src={infor_logo} alt="People sat around a table" className="md:w-1/2 h-auto" />
                    </div>
                    <div className="flex flex-col items-left md:p-5">
                        <h3 className="text-4xl text-langreen font-bold">Infor</h3>
                        <p className="mt-2">Lanwoods, as an Infor certified Delivery Partner, enjoys access to a vast array of resources, enhancing its customer service capabilities. Leveraging this partnership, Lanwoods is equipped to provide comprehensive services, including training, support, and project management. These services cater to both on-premises and cloud-based scheduling solutions and are available to various industries worldwide. This collaboration ensures that, as a customer, you receive enriched, efficient, and expert solutions tailored to meet your industry-specific needs.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;