import linkedin_logo from '../images/linkedin_logo.png';
import infor_logo_bw from '../images/infor_logo_bw.png';

const textbox = () => {
    return (
        <div className="p-10 bg-black  text-white">
            <div className="flex justify-between border-b p-3 border-white">
                <h4 >Lanwoods. all rights reserved.</h4>
                <div className="flex justify-end">
                    <a href="https://www.linkedin.com/in/roy-hoult/">
                        <img src={linkedin_logo} alt="Linked In Logo" className="w-1/2"/>
                    </a>
                </div>
            </div> 

            <div className="flex flex-col sm:flex-row md:justify-between pt-10 gap-3">
                <div className="flex flex-col pr-10 ">
                    <a href="/partners" className="text-langreen-alt font-semibold">Our Partners &gt;</a>
                </div>
                <div className="flex flex-col">
                    <a href="/contact" className="text-langreen-alt font-semibold">Contact &gt;</a>
                </div>
                <div className="flex md:justify-end justify-center">
                    <img src={infor_logo_bw} alt="Infor Logo"  className="w-1/2"/>
                </div>
            </div>
        </div>
    );
}

export default textbox;
