

const textbox = (props) => {
    return (
        <div className="p-10">
            <div>
                <h2 className="text-langreen font-bold text-4xl">{ props.title }  </h2>
                    <h2 className=" text-4xl pt-2 "> { props.subtitle } </h2>
            </div> 
            <div className="flex items-center justify-center pt-3">

            <div className="text-lg w-[37.4em] text-slate-700">
                <p>
                { props.children }
                </p>
            </div>
            </div>
        </div>
    );
}

export default textbox;